import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { rtkQueryApi } from './api/rtk-query-api';
import comparisonsTabDataSlice from './slices/comparisons-data-slice';
import gptSlice from './slices/gpt-slice';
import parsingProgressSlice from './slices/parsing-progress-slice';
import phrasesLibraryDataSlice from './slices/phrases-library-data-slice';
import projectArticlesSlice from './slices/project-articles-slice';
import projectPhrasesDataSlice from './slices/project-phrases-data-slice';
import reportProductSlice from './slices/report-product-slice';
import reportProjectSlice from './slices/report-project-slice';
import userSlice from './slices/user-slice';
import tipsSlice from './slices/tip-slice';

const logger = createLogger({
  collapsed: true,
});

export const store = configureStore({
  reducer: {
    user: userSlice,
    tipsMode: tipsSlice,
    parsingProgress: parsingProgressSlice,
    reportProduct: reportProductSlice,
    reportProject: reportProjectSlice,
    projectPhrasesData: projectPhrasesDataSlice,
    phrasesLibraryData: phrasesLibraryDataSlice,
    comparisonsTabData: comparisonsTabDataSlice,
    gpt: gptSlice,
    projectArticles: projectArticlesSlice,
    [rtkQueryApi.reducerPath]: rtkQueryApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
    });
    const middlewares = defaultMiddleware.concat(rtkQueryApi.middleware);

    if (import.meta.env.DEV) {
      middlewares.push(logger);
    }

    return middlewares;
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
