import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const tipsSlice = createSlice({
  name: 'tipsSlice',
  initialState: { tipsMode: true },
  reducers: {
    setTipsMode(state, action: PayloadAction<boolean>) {
      state.tipsMode = action.payload;
    },
  },
});

export const { setTipsMode } = tipsSlice.actions;

export default tipsSlice.reducer;
