import { useCallback, useEffect, useState } from 'react';

export default function useLocalStorage<T>(
  key: string,
  data?: T,
  options: Partial<{
    serializable: boolean;
  }> = {},
): [T, React.Dispatch<React.SetStateAction<T>>, refresh: () => void] {
  const _key = `${import.meta.env.VITE_PROJECT_USER_STORAGE_ID}_${key}`;
  const [state, setState] = useState<T>(() => {
    const existingData = localStorage.getItem(_key);

    if (existingData) {
      return options.serializable ? JSON.parse(existingData) : existingData;
    }

    return data;
  });

  useEffect(() => {
    localStorage.setItem(_key, options.serializable ? JSON.stringify(state) : String(state));
  }, [_key, state, options.serializable]);

  const refresh = useCallback(() => {
    const existingData = localStorage.getItem(_key);

    if (existingData) {
      setState(options.serializable ? JSON.parse(existingData) : existingData);
    }
  }, [_key, options.serializable]);

  return [state, setState, refresh];
}
