import { WsAgent } from '../utils/wsAgent';

// WS
export function emailToSimpleId(email: string): string {
  let id = '';

  for (let i = 0; i < email.length; i++) {
    const charCode = email.charCodeAt(i);
    id += (charCode % 36).toString(16);
  }

  if (id.length < 24) {
    id = id.padEnd(24, '0');
  }

  return id.slice(0, 24);
}

// const wsClientIdEnd = Math.random().toString(16).slice(2);
// export const WS_CLIENT_ID = sessionStorage.getItem('wsClientId') || '0'.repeat(24 - wsClientIdEnd.length) + wsClientIdEnd;
// sessionStorage.setItem('wsClientId', WS_CLIENT_ID);

export const WS_GATEWAY_URL = 'wss://commonapi.marpla.ru/ws/gateway';
export const WS_AGENT = new WsAgent();

export const formatTime = (time: number): string => {
  const seconds = Math.floor(time / 1000);

  if (seconds < 60) {
    return `${seconds} секунд`;
  }

  const minutes = Math.floor(seconds / 60);

  return `${minutes} минут`;
};

// TOOLTIP
export const TOOLTIP_PARSING_PHRASES_LIMIT = (time: number): string => `Сейчас нельзя запустить парсинг. Повторите попытку через ${formatTime(time)}.`;
export const TOOLTIP_PARSING_PHRASES_MOVING_NEED =
  'Сейчас невозможно запустить парсинг. Сначала перенесите фразы в список.';
export const TOOLTIP_PARSING_PHRASES_DATE_NOT_TODAY =
  'Вы не можете запустить парсинг за прошлую дату. Для запуска парсинга переключитесь на текущую дату';
export const TOOLTIP_PARSING_PHRASES_POSITIONS_BUTTON =
  'Определение позиций артикулов по ключевым запросам в поиске WB. Будут проверены все артикулы данного проекта';

// COMMON
export const PARSING_INSTRUCTION =
  'Ручной запуск парсинга позиций возможен для проектов, количество фраз которых не превышает 10 000. Пожалуйста, очистите проект от мусорных фраз для возможности ручного запуска парсинга позиций фраз проекта.';

export const NO_RESULT = '–';
export const FORMATTER_LANG = 'ru-RU';

export const CURRENCY_TOKENS = {
  'RUB': '₽',
  'USD': '$',
};
